const GRID_MIN_ROWS = 6

const rowHeight = 40

export const getRowsPerPage = (gridHeight?: number): number => {
  if (!gridHeight) {
    return 0
  }

  return Math.max(Math.floor(gridHeight / rowHeight - 2), GRID_MIN_ROWS)
}
